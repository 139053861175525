import React from 'react'
import SingleLayout from './SingleLayout'

const LayoutDetails = () => {
    return (
        <SingleLayout />
    )
}

export default LayoutDetails
