import Breadcrumbs from "../shared/Breadcrumbs"
import { useState,useEffect } from "react";
import config from '../../config.json';
import { isAuthenticated, logout } from '../shared/repository';
import { Link, Redirect} from 'react-router-dom'


const MyAccount = () => {
    const [accountDetails, updateAccountDetails]=useState({
      "fullname":'loading fullname...',
      "email":"loading email...",
      "bids":"loading...",
      "wins":'loading...',
      "payments":'loading...',
      "socialMediaHandles":'loading...'
    });

    useEffect(()=>{
      const controller = new AbortController();
      const signal = controller.signal;
      fetchAccountSummary()

      return ()=> controller.abort()
    },[]
  )

    // const tokenBlob= new Blob([JSON.stringify({ email,password}, null, 2)], { type: 'application/json' });

    const fetchAccountSummary=()=>{
        const options = {
          method: 'GET',
          // body: tokenBlob,
          mode: 'cors',  
          accepts: "application/json",         
          cache: 'default',
          contentType : "application/json",
          headers:{
            "Authorization":"Bearer "+ localStorage.getItem('accessToken')
          }
        }

        if(!isAuthenticated())
        {         
          return <Redirect to='/login' />
        }

        fetch(config.BASE_URL+'user/my-account', options)
        .then(response =>{
          // console.log(response)
          // if(response.ok){
          response.json()
          .then(data => {
            if(!data.status)
            {
              updateAccountDetails({
                ...accountDetails,
                  "fullname":null,
                  "email":null,
                  "bids":null,
                  "wins":null,
                  "payments":null                
              });
            }else{
              // console.log(data.socialMediaHandles)
              updateAccountDetails({
                ...accountDetails,
                  "fullname":data.fullname,
                  "email":data.email,
                  "bids":data.bids,
                  "wins":data.wins,
                  "payments":data.payments,
                  "socialMediaHandles":data.socialMediaHandles               
              });
            }
          })
        // }else{
        //   // if(response.status==401){
        //     return logout()
        //   // }
        // }
        })
        .catch(err => alert(err));
        }
  
    return (!isAuthenticated())?
            <Redirect to='/login' />
     : (
        <div>
            <Breadcrumbs title="My Account" />
        <section id="services2" className="services2">
      <div className="container" data-aos="fade-up">


        <div className="row">
          <div className="section-title">
            <h2>My Account Summary</h2>
            <p>Summary of my account</p>
          </div>       
        </div>
        <div className="row mb-4 account-info-row team">
        <div className="col-md-4 text-left">
          <Link to='/my-profile' className="btn btn-warning"><i class="bi bi-info-circle"></i> My Profile</Link>
        </div>
        <div className="col-md-4 offset-md-4 text-right">
            <div className="member d-flex align-items-end">
              <div className="pic"><img src="/assets/images/icons/default-profile-icon2.jpg" className="img-fluid" alt="" /></div>
              <div className="member-info">
                <h4>{accountDetails.fullname}</h4>
                <span>{accountDetails.email}</span>
                {/* <p>Explicabo voluptatem mollitia et repellat</p> */}
                <div className="social">
                    {Array.isArray(accountDetails.socialMediaHandles)?accountDetails.socialMediaHandles.map(handle => (
                    <a href={`https://${handle.handle}`} target='_blank' key={handle.type}>
                        <i className={`ri-${handle.type}-fill`}></i>
                    </a>
                    )):"No social media handles to display"}              
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box">
              <div className="icon">
                <i className="bx bxs-hand"></i>
              </div>
              <h4>{accountDetails.bids>0?<Link to="/my-bids"> My Bids ({accountDetails.bids})</Link>: "My Bids (Yet to bid)"}</h4>
              <p>This is a total of the bids you have made on this platform so far from the day you registered till date</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="icon"><i className="bx bxs-trophy"></i></div>
              <h4><a href=""> My Wins ({accountDetails.wins})</a></h4>
              <p>This is a total of the bids you have won so far from the day you registered till date</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-money"></i></div>
              <h4><Link to="/my/payments">My Payments</Link></h4>
              <h5>{accountDetails.payments}</h5>
              <p>This is the total payments you have made through this platform</p>
            </div>
          </div>
        </div>

      </div>
    </section>

        </div>
    )
}

export default MyAccount
