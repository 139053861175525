import React from 'react'

const AboutIntro = () => {
    return (
        <section id="about" className="about">
      <div className="container">

        <div className="row content">
          <div className="col-lg-6">
            <h5>There are 3 methods traditionally used to conduct property sales </h5>
            <ul className='list-group list-group-flush py-3'>
              <li className='list-group-item'><i className="ri-check-double-line"></i>1. Private treaty negotiations </li>
              <li className='list-group-item'><i className="ri-check-double-line"></i>2.	Sealed or open bidding  </li>
              <li className='list-group-item'><i className="ri-check-double-line"></i>3.	Auctions</li>
            </ul>
            <p>The first 2 methods are common and have not been seen to be so prevalent and transparent. They also prove to be very tedious and prolonged in Nigeria especially when it involves properties or contracts</p>
            <p>However, the Auction method is very transparent, quick, and easy to administer.</p>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <p>
            With many estates being sold and some yet to start, it has become imminent to have a system for getting across to potential customers efficiently leveraging technology both in reaching them and in managing certain critical stages of the transactions in the real estate business. Such will build confidence among the prospective customers and in turn better business prospect for the operators in the sector.
            </p>
            <p>To achieve this objective, Bulletins & Enquiries Networking Services Ltd in collaboration with Perfect Solutions Nigeria Enterprises, a software development agency, has built an online auction and bidding platform powered with modern technology to deliver the following features below.</p>           
          </div>
        </div>
      </div>
    </section>
    )
}

export default AboutIntro
