import React from 'react'
import { Link } from 'react-router-dom'

const CallToActionBar = () => {
    return (
        <section id="clients" className="clients section-bg">
        <div className="py-14 bg-dark py-4">
                                      <div className="container">
                                        <div className="row">
                                          <div className="offset-lg-2 col-lg-8 col-md-12 col-12 text-center">
                                            <span className="fs-4 text-warning ls-md text-uppercase
                                              fw-semi-bold">Step in with a bid

                                            </span>
                                             {/* heading   */}
                                            <h2 className="display-6 mt-4 mb-3 text-white fw-bold">A bid can be all you need to be a winner. Give it a shot!</h2>
                                              {/* <!-- para  --> */}
                                            <p className="lead text-white-50 px-lg-8 mb-6">Developed for you to own the property of your dream with ease.</p>
                                            <Link to="/signup" className="btn btn-warning"> Join Free</Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
      </section>
    )
}

export default CallToActionBar
