import React from 'react'
import SidebarSinglePlot from './SidebarSinglePlot'

const SingleLayoutSidebar = (props) => {
    const sidebarPlots=props.plots.map(plot =>{   
        return <SidebarSinglePlot key={plot.id} label={plot.landLabel} brief={plot.brief} price={plot.reserveAmount} id={plot.landId} layoutImg={props.layoutImg} />                
     })

    return (
        <div className="sidebar">
            <h3 className="sidebar-title">Plots </h3>
            <hr />
              <div className="sidebar-item recent-posts">
                {sidebarPlots}
              </div>
        </div>
    )
}

export default SingleLayoutSidebar
