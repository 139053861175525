import React,{useEffect,useState} from 'react'
import Breadcrumbs from './shared/Breadcrumbs'
import { CModal } from '@coreui/react'
import { CModalBody } from '@coreui/react'
import {CModalHeader} from  '@coreui/react'
import {CModalTitle} from '@coreui/react/'
import { httpOptions } from './shared/repository'
import config from '../config.json'


const Contact = () => {
    const puzzleQuestionsAndAnswers=[
        {question:"2 X 3", answer:6},
        {question:"4 X 3", answer:12},
        {question:"3 X 6", answer:18},
        {question:"8 + 1", answer:9},
        {question:"4 + 3", answer:7},
        {question:"7 - 7", answer:0},
        {question:"12 - 4", answer:8},
        {question:"10 + 1", answer:11},
        {question:"11 - 9", answer:2},
        {question:"4 - 3", answer:1},
        {question:"6 X 4", answer:24},
        {question:"3 X 5", answer:15},
        {question:"15 - 10", answer:5},
        {question:"2 + 11", answer:13}
    ]

    const [messageDetails,updateMessageDetails]=useState({
        "senderName":"",
        "senderEmail":'',
        "senderPhone":'',
        "subject":'',
        "message":''       
    })

    const [puzzleAnswer,setPuzzleAnswer]=useState('')
    const [puzzleItem, choosePuzzleItem]=useState(puzzleQuestionsAndAnswers[Math.floor(Math.random()*puzzleQuestionsAndAnswers.length)]);  
    const [statusMessage,setStatusMessage]=useState('loading...')

   //alert("Puzzle answer is "+puzzleAnswer)
useEffect(()=>{
    choosePuzzleItem(puzzleQuestionsAndAnswers[Math.floor(Math.random()*puzzleQuestionsAndAnswers.length)])

},[statusMessage])
//alert("Expected Answer is "+puzzleItem.answer)
    const [modalVisible,setModalVisible]=useState(false)
    const [responseStatus,setResponseStatus]=useState('default')
    

    const SubmitContactForm=(e)=>{
        e.preventDefault()           
        return sendMessage(messageDetails);        
    }

    const handleChange=(e)=>{
        updateMessageDetails({...messageDetails,[e.target.name]:e.target.value})
    }

    const sendMessage=(messageDetails)=>{

        if(parseInt(puzzleAnswer) !== parseInt(puzzleItem.answer)){
           setPuzzleAnswer('')
           
           setResponseStatus('danger')
           setStatusMessage(`You did not supply a correct answer to the arithmentic question.  Please retry`)
           setModalVisible(true)
            return                  
        }

        updateMessageDetails({...messageDetails,"submittedPuzzleSolution":puzzleAnswer, expectedPuzzleSolution:puzzleItem.answer})
        
      fetch(config.BASE_URL+'contact/sendmessage', httpOptions("POST",new Blob([JSON.stringify(messageDetails, null, 2)], { type: 'application/json' }))
      ).then(response =>{
          console.log(response)
          if(response.ok){
              response.json()
              .then(data =>{
                setResponseStatus(data.status?'success':'danger');
                setStatusMessage(data.description)
                setModalVisible(true)
                return
              })
          }else{
            setResponseStatus('danger');
                setStatusMessage("System Error! Please retry")
                setModalVisible(true)
                return
          }      
        }).catch(err =>{
            setResponseStatus('danger');
            setStatusMessage(err.message)
            setModalVisible(true)
            return
        })
    
}

    return (
        <main>
        <Breadcrumbs title="Contact" />
    <section id="contact" class="contact">
        <div class="container">
            <div class="row mt-5">
            <div class="col-lg-4">
                <div class="info">
                <div class="address">
                    <i class="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>Lekki Phase 1, Lagos, Nigeria</p>
                </div>

                <div class="email">
                    <i class="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>info@epabs.com</p>
                </div>

                <div class="phone">
                    <i class="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>+234 803 3456 778</p>
                </div>

                </div>

            </div>

            <div class="col-lg-8 mt-5 mt-lg-0">

                <form action="" method="post" class="php-email-form" onSubmit={SubmitContactForm}>
                <div class="row">
                    <div class="col-md-6 form-group">
                    <input type="text" name="senderName" class="form-control" id="name" placeholder="Your Name" required onChange={handleChange} />
                    </div>
                    <div class="col-md-6 form-group mt-3 mt-md-0">
                    <input type="number" min={0} class="form-control" name="senderPhone" id="phone" placeholder="Your Phone Number" required onChange={handleChange}/>
                    </div>
                </div>
                <div class="form-group mt-3">
                    <input type="email" class="form-control" name="senderEmail" id="email" placeholder="Your Email Address" value={messageDetails.email} required onChange={handleChange}/>
                </div>
                <div class="form-group mt-3">
                    <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required onChange={handleChange} value={messageDetails.subject}/>
                </div>
                <div class="form-group mt-3">
                    <textarea class="form-control" name="message" rows="5" placeholder="Message" required onChange={handleChange} value={messageDetails.message} />
                </div> <div class="form-group mt-3">
                    <input type="text" class="form-control" name="submittedPuzzleAnswer" id="answer" placeholder={`What is ${puzzleItem.question} ?`}  required value={messageDetails.puzzleAnswer} onChange={(e)=>setPuzzleAnswer(e.target.value)} />
                </div>          
                <div class="text-center"><button type="submit">Send Message</button></div>
                <CModal alignment="center" scrollable visible={modalVisible} onClose={() => {setModalVisible(false); setResponseStatus("default")}}>
      <CModalHeader>
        <CModalTitle>Message Status</CModalTitle>
      </CModalHeader>
      <CModalBody>
       <div className={`alert alert-${responseStatus}`}>        {statusMessage}
       </div>
      </CModalBody>
          </CModal>
                </form>
               
            </div>

            </div>

        </div>
        </section>
            </main>
    )
}

export default Contact
