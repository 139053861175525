import { Link,Redirect } from 'react-router-dom'
import { Form,Col,Row } from 'react-bootstrap'
import Breadcrumbs from './shared/Breadcrumbs'
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import config from '../config.json';
import { useState } from 'react';
import { isAuthenticated, storeAuthToken,googleResponse, facebookResponse, onFailure  } from './shared/repository';

const Signup =()=>{
    const [isLoading,setLoading]=useState(false);
    const fbstub="www.facebook.com/";
    const twitterstub="www.twitter.com/";
    const [userData, updateUserData] = useState({
        Email:'',
        ConfirmEmail:'',
        PhoneNumber:'',
        ConfirmPhoneNumber:'',
        Surname:'',
        Othernames:'',
        Password:'',
        ConfirmPassword:'',
        FacebookAddress:'',
        TwitterAddress:''
    });
    
    const submitRegForm = (event) => {
        event.preventDefault();
        setLoading(true);
        const tokenBlob= new Blob([JSON.stringify(userData, null, 2)], { type: 'application/json' });
        const options = {
          method: 'POST',
          body: tokenBlob,
          mode: 'cors',  
          accepts: "application/json",         
          cache: 'default',
          contentType : "application/json",
        }
    
        fetch(config.BASE_URL+'accounts/register', options)
        .then(res => {
            res.json()
            .then(accessToken => {
                if(accessToken.status){
                   storeAuthToken(accessToken)
                    window.location.href='/';
                    }else{
                        alert(accessToken.description);
                        setLoading(false)
                    }               
            });
        })
        .catch(err => {
            alert(err)
            setLoading(false)
        });

        setLoading(false)
    }

    function myChangeHandler(event) {

        updateUserData({ ...userData, [event.target.name]: event.target.value });
    }
      
    function myTncChangeHandler(){
        let tnc=document.getElementById('tnc').value;
        if(tnc === 'on'){
            updateUserData({...userData,"AcceptTnc":true})
        }else{
            updateUserData({...userData,"AcceptTnc":false})
        }
    }

    const content = (!isAuthenticated())?
    (
        <div>
            <Breadcrumbs title="Sign Up" />
            <section>
                <div className="container">
                    <div className="section-title">
                        <h2>Sign Up</h2>
                        <p>Start by Completing this form or through 3rd party account(Facebook, Google or Twitter)</p>
                    </div>
                    <div className='row'>
                        <div className='col-md-4 pb-5'>
                            <h2>Sign Up with any of these<i className="bi bi-caret-down"></i></h2>
                            <hr />
                            <div className='mt-4 text-center'>
                            <FacebookLogin
                            appId={config.FACEBOOK_APP_ID}
                            autoLoad={false}
                            fields="name,email,picture"
                            // scope="public_profile"
                            textButton="Login"
                            size='small'
                            icon='bi bi-facebook'
                            callback={facebookResponse}
                            onFailure={onFailure}
                            style={{marginRight:'20px'}}
                        />  
                        <GoogleLogin
                            clientId={config.GOOGLE_CLIENT_ID}
                            buttonText="Login"
                            onSuccess={googleResponse}
                            onFailure={onFailure}
                        />
                        {/* <a className='btn btn-info' style={{color:'white'}}><i className='bi bi-twitter'></i> Twitter</a>  */}
                            </div>
                        </div>
                        <div className='col-md-8' style={{borderLeft:'1px solid gray'}}>
                        <h2>Or Complete this form</h2>
                        <hr />
                        <div className='card'>
                            <div className='card-body'>
                            <Form onSubmit={(e)=>submitRegForm(e)} >
                                <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control name="Email" type="email" placeholder="Enter email" required onChange={myChangeHandler} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridConfirmEmail">
                                <Form.Label>Confirm Email</Form.Label>
                                <Form.Control name="ConfirmEmail" type="email" placeholder="Confirm your email" required onChange={myChangeHandler} />
                                </Form.Group>    
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridPhone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control name="PhoneNumber" type="text" placeholder="Enter phone" required onChange={myChangeHandler} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridConfirmPhone">
                                <Form.Label>Confirm Phone</Form.Label>
                                <Form.Control name="ConfirmPhoneNumber" type="text" placeholder="Confirm your phone number" required onChange={myChangeHandler} />
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridSurname">
                                <Form.Label>Surname</Form.Label>
                                <Form.Control name="Surname" type="text" placeholder="Enter your surname" required onChange={myChangeHandler} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridOthernames">
                                <Form.Label>Other Names</Form.Label>
                                <Form.Control name="Othernames" type="text" placeholder="Enter your other names" required onChange={myChangeHandler} />
                                </Form.Group>
                            </Row>
                                <Row className='mb-3'>
                                <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control name="Password" type="password" placeholder="Password" required onChange={myChangeHandler} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridConfirmPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control name="ConfirmPassword" type="password" placeholder="Confirm your password" required onChange={myChangeHandler} />
                                </Form.Group>
                                </Row>
                                <Row className="mb-3">    
                                <Form.Group as={Col} controlId="formGridFacebook">
                                <Form.Label>Facebook profile address</Form.Label>
                                <Form.Control name="FacebookAddress" 
                                type="text"
                                value={userData.FacebookAddress ||fbstub} 
                                placeholder="e.g. www.facebook.com/you-facebook-username" 
                                onChange={myChangeHandler} 
                                />

                                </Form.Group>
                            </Row>
                            <Row className="mb-3">    
                                <Form.Group as={Col} controlId="formGridTwitter">
                                <Form.Label>Twitter handle</Form.Label>
                                <Form.Control name="TwitterAddress" 
                                type="text" 
                                placeholder="e.g. www.twitter.com/you-twitter-username" 
                                onChange={myChangeHandler} 
                                value={userData.TwitterAddress || twitterstub}
                                />
                                </Form.Group>
                            </Row>

                            <div className="form-group mb-3">
                                <div className="">        
                                <label className='align-self-center'><input type="checkbox"  name="AcceptTnc" id="tnc" required className='align-self-center' onChange={myTncChangeHandler} /> I accept  
                                    </label> <Link to='/tnc'> the Terms and Conditions</Link>
                                </div>
                            </div>
                            {isLoading?<h5>Loading...</h5>:<button type='submit' className='submit-btn'>
                                Submit
                            </button>
                            }
                            </Form>
                            </div>
                        </div>       
                        </div>
                    <div class="col-md-8 mt-5 mt-md-0 offset-md-2">
                    </div>
                    </div>
                </div>
            </section>
        </div>
    ) : <Redirect to='/'/>

                return (
            
            <main id='main'>
                { content }
            </main>
        )
}
export default Signup
