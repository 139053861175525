import React from 'react'
import { Link } from 'react-router-dom'

const SidebarSinglePlot = (props) => {
    return (
        <div className="post-item clearfix">
            <img src={`/assets/img/slide/${props.layoutImg}`} alt={props.label} />
            <h4><Link to={`/lands-and-layouts/plot/${props.id}`}>{props.label} - {props.price}</Link></h4>
            <p>{props.brief}</p>
            <hr />
        </div>
    )
}

export default SidebarSinglePlot
