import React,{useState} from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Breadcrumbs from './shared/Breadcrumbs'
import config from '../config.json'

const PasswordReset = () => {
// const {token}=useParams();
const history= useHistory();

const token = new URLSearchParams(useLocation().search).get('token');

const [details, setDetails]=useState({'token':token,email:'',password:'',confirmPassword:''})
// const [password, setPassword]=useState()
// const [confirmPassword, setConfirmPassword]=useState()

const handleChange=(e)=>{
   setDetails({...details,[e.target.name]:e.target.value})
}

const sendDetailsForPasswordReset=()=>{

    if(details.email ===''){
        alert("Please enter your email address")
    }else if(details.password !== details.confirmPassword){
        alert("Your password and confirmation do not match")
    }else{
        //console.log(details)
        const tokenBlob = new Blob([JSON.stringify(details, null, 2)], { type: 'application/json' });
        const options = {
            method: 'POST',
            body: tokenBlob,
            mode: 'cors',  
            accepts: "application/json",         
            cache: 'default',
            contentType : "application/json",
            }
        const pwResetPath=config.BASE_URL+'accounts/resetpassword';
        //console.log(details.token)
        fetch(pwResetPath, options)
        .then(r => {
            return r.json()
            .then(response => {
                alert(response.description)
                history.push('/login')
            });
        })      
        .catch(e=>{
            alert('Sorry, Error Occured: '+JSON.stringify(e));
        })
    }
}

// const validateAndSend=()=>{
//     if(details.email ==''){
//         alert("Please enter your email address")
//     }else if(details.password != details.confirmPassword){
//         alert("Your password and confirmation do not match")
//     }
//     else{
//        sendDetailsForPasswordReset(details);      
//     }
// }

    return (
        <div>
            <Breadcrumbs title='Create a new password' />
            <section id="blog" class="blog">
                <div class="container" data-aos="fade-up">

                    <div class="row">

                        <div class="col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
                            <div className='card'>
                                <div className='card-body'>
                                    <h5 className='text-center'>Createa new password for you account</h5>
                    <div class="mb-3 row">
                        <label for="Email" className="col-sm-2 col-form-label">Email</label>
                        <div className="col-sm-10">
                        <input type="text" id="Email" name='email' className="form-control"  placeholder="Enter your email here" value={details?.email || ''} onChange={handleChange} />
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label for="Password" className="col-sm-2 col-form-label">Password</label>
                        <div className="col-sm-10">
                        <input type="password" id="Password" name='password' className="form-control"  placeholder="Enter your new password" value={details?.password || ''} onChange={handleChange} />
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label for="confirmPw" className="col-sm-2 col-form-label">Confirm Password</label>
                        <div className="col-sm-10">
                        <input type="password" id="confirmPw" className="form-control" name='confirmPassword' placeholder="Enter your password again" value={details?.confirmPassword || ''} onChange={handleChange} />
                        </div>
                    </div>

  <div class="mb-3 row">
                        
                        <div className="d-flex justify-content-end">
                        <button className='btn btn-success btn-lg' onClick={sendDetailsForPasswordReset}>Request For a Reset</button>
                        </div>
  </div>
 
 
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PasswordReset
