import {useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Breadcrumbs from '../shared/Breadcrumbs'
import config from '../../config.json'
import { isAuthenticated } from '../shared/repository'

const MyProfile = () => {
    const controller = new AbortController();
    const [profile, setProfile]=useState({
    "surname":'...',
    "othernames":'...',
    "email":'...',
    "phoneNumber":'...',
    "socialMediaHandles":{}
})


useEffect(() => {   
    fetchProfileInfo()
    return ()=> controller.abort()
}, [])

const fetchProfileInfo=() => {
    if(!isAuthenticated())
    {         
      return <Redirect to='/login' />
    }

    const options = {
        method: 'GET',
        // body: tokenBlob,
        mode: 'cors',  
        accepts: "application/json",         
        cache: 'default',
        contentType : "application/json",
        headers:{
          "Authorization":"Bearer "+ localStorage.getItem('accessToken')
        }
      }

    
      fetch(config.BASE_URL+'user/my-profile', options)
      .then(response =>{
        // console.log(response)
        // if(response.ok){
        response.json()
        .then(data => {
          if(!data.status)
          {
           alert(data.description)
          }else{
            setProfile(data);   
          }
        })     
      })
      .catch(err => alert(err));
      }

    return (
        (!isAuthenticated())?<Redirect to='/login' />:
            <div>
                <Breadcrumbs title="My Profile" />
                <section id="services2" className="services2">
            <div className="container" data-aos="fade-up">
            <div className="row">
            <div className="section-title">
                <h2>My Profile Details</h2>
                <p>Current Profile Data</p>
            </div>       
            </div>
            <div className='row'>
                <div className='col-md-7'>
                    <h5>Biodata</h5>
                    
                    <ul className='list-group'>
                        <li className='list-group-item'>Surname: {profile.surname}</li>
                        <li className='list-group-item'>Other Names: {profile.othernames}</li>
                        <li className='list-group-item'>Email: {profile.email}</li>
                        <li className='list-group-item'>Phone: {profile.phoneNumber}</li>
                    </ul>
            
                </div>
                <div className='col-md-5 d-flex justify-content-end'>
                   
                    <ul className='list-group'>
                    <h5>Social Medial Handles</h5>
                        {   
                        <>
                            <li className="list-group-item"> Facebook Address: {(profile.socialMediaHandles.Facebook != null)?profile.socialMediaHandles.Facebook :''}</li>
                            <li className="list-group-item"> Twitter Address: {(profile.socialMediaHandles.Twitter != null)?profile.socialMediaHandles.Twitter :''}</li>
                            </>
                        }
                    </ul>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-md-4 text-center'>
                <Link to='/edit-profile' className='btn btn-lg btn-warning'><i class="bi bi-pencil-square"></i> Edit Profile</Link>
                </div>
            </div>
            </div>
        </section>
        </div>
    )
}

export default MyProfile
