import Button from '@restart/ui/esm/Button';
import React from 'react'
import {Navbar,Nav,Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { isAuthenticated, logout } from './repository';

const NavMenu = () => {

  const authLink=() => {
      return (
        (isAuthenticated()) ?
        <>
          <Link to='/my-account/' className='nav-link' title={localStorage.getItem('user')}>My Account ({localStorage.getItem('user')})</Link> 
          <Link to='#' onClick={logout} className='nav-link'>Logout</Link>
        </>
        :
        <>
        <Link to='/login/' className='nav-link'>Login</Link>
        <Link to='/signup/' className='getstarted'>Join</Link>
        </>
      )
  }
    
    return (
<Navbar expand="lg" bg="dark" variant="dark"  fixed="top" id='header'>
  <Container>
    <Navbar.Brand>
      <Link to='/'>
        <img src="/assets/images/logo-nobg-100.png" alt="ePABS platform" className="img-fluid logo" />
      </Link>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
      <Nav className="ms-auto">
        <Link to="/" className='nav-link'>Home</Link>
        {/* <Link to="/the-concept/" className='nav-link'>The Concept</Link> */}
        <Link to="/how-it-works/" className='nav-link'>How It Works</Link>
        <Link to="/faq/" className='nav-link'>FAQ</Link>
       
        <Link to="/lands-and-layouts/" className='nav-link'>Lands and Layouts</Link>
        <Link to="/contact-us#" className='nav-link'>Contact Us</Link>
        {
          authLink()
        }
        
     </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
    )
}

export default NavMenu
