import React from 'react';

class ReceiptBody extends React.Component {
    constructor(props) {  
        super(props)         
        this.imgsrc='/assets/images/logo.png';
      }

render(){

  return       <div className='col-md-6 offset-md-3' id="receipt-div">
  <div className='row'>
      <div className='col text-start d-flex align-items-center justify-content-start overflow-hidden'>
        <img src={this.imgsrc} className="img-fluid" />
      </div>
      <div className='col'>
        <h3 className='text-end'>No: {this.props.receiptData.receiptNumber}</h3>
      </div>
    </div>
    <div className='row'>
      <div className='col-12'>
        <h3 className='text-center'>Payment Receipt</h3>
      </div>
    </div>
  <div className='row' style={{padding:'10px'}}>
    <table className='table table-striped table-bordered'>
        <tbody>
            <tr>
                <th width="30%">Amount Paid</th>
                <td>{this.props.receiptData.amountInFigures}</td>
            </tr>
            <tr>
                <th width="30%">Amount in Words</th>
                <td>{this.props.receiptData.amountInWords}</td>
            </tr>
            <tr>
                <th width="30%">Purpose</th>
                <td>{this.props.receiptData.paymentPurpose}</td>
            </tr>
            <tr>
                <th width="30%">Time Paid</th>
                <td>{this.props.receiptData.paymentTime}</td>
            </tr>
            <tr>
                <th width="30%">Received From</th>
                <td>{this.props.receiptData.payerName}</td>
            </tr>
        </tbody>
    </table>
  </div>
</div>
};
}
export default ReceiptBody;
