import React, {useState} from 'react'
import Breadcrumbs from './shared/Breadcrumbs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import config from '../config.json'

const ForgotPassword = () => {
 const history=useHistory();

 const [email,setEmail]=useState('')

 const updateEmail=(e)=>{
    setEmail(e.target.value)
 }

 const sendEmailForReset=()=>{
    if(email === ''){
        alert("You need to supply your email address")
    }else{
        const tokenBlob = new Blob([JSON.stringify({ email }, null, 2)], { type: 'application/json' });
        const options = {
            method: 'POST',
            body: tokenBlob,
            mode: 'cors',  
            accepts: "application/json",         
            cache: 'default',
            contentType : "application/json",
          }
        const pwResetPath=config.BASE_URL+'Accounts/SendPasswordResetLink';
        fetch(pwResetPath, options)
        .then(async r => {
          return await r.json()
            .then(response => {
                alert(response.description)
                if(response.status)history.push('/login')
          });
      })      
      .catch(e=>{
          alert('Sorry, Error Occured: '+JSON.stringify(e));
      })
    }
 }
 

    return (
        <div>
            <Breadcrumbs title="Forgotten Password" />
            <section id="blog" class="blog">
                <div class="container" data-aos="fade-up">

                    <div class="row">

                        <div class="col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
                            <div className='card'>
                                <div className='card-body'>
                                    <h5 className='text-center'>Forgot your password?</h5>
                                    <div class="mb-3 row">
                        <label for="Email" className="col-sm-2 col-form-label">Email</label>
                        <div className="col-sm-10">
                        <input type="text" id="Email" className="form-control"  placeholder="Enter your email here" value={email} onChange={updateEmail} />
                        </div>
  </div>

  <div class="mb-3 row">
                        
                        <div className="d-flex justify-content-end">
                        <button className='btn btn-success btn-lg' onClick={sendEmailForReset}>Request For a Reset</button>
                        </div>
  </div>
 
 
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ForgotPassword
