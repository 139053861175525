import { Form,Button } from 'react-bootstrap'
import {Link } from 'react-router-dom'
import { useState } from 'react'
import config from '../../config.json';
import { useParams } from 'react-router-dom';
import { httpOptions } from '../shared/repository';
import { useEffect } from 'react';

const BiddingModal = () => {

    const controller= new AbortController();
    const {id}=useParams();
    let [bid, setBid] = useState(0)
    const [loading,setLoading]=useState(false)
    const [reserveAmount, setReserveAmount]=useState(0)

    const reserveAmountString = `NGN ${parseInt(reserveAmount).toLocaleString("en-NG")}`

    let [AcceptTnc, setTncAcceptance]=useState(false)

    let bidstring=bid > 0 ?`(NGN ${parseInt(bid).toLocaleString("en-NG")})` :''

    const handleTncAcceptance=()=>{
        let accepted=document.getElementById('tnc').checked
            setTncAcceptance(accepted)      
    }

  
    const submitBiddingForm=(e)=>{
        e.preventDefault();
        if(!AcceptTnc)
        {
            return alert("You cannot submit a bid without accepting the terms and conditions. Please check the box to accept to continue. Otherwise, close the bidding form")
        }
        
        if(parseFloat(bid) < 1000){
          return alert("You cannot bid less than NGN 1,000")
        }

        let bidAmount=parseFloat(bid);
        
        if(bidAmount < reserveAmount){
          return alert("You cannot place a bid less than the allowed lowest bid amount of "+ reserveAmountString)
        }

        const bidData={ AcceptTnc,amount:bid,landId:id};
        const tokenBlob= new Blob([JSON.stringify(bidData, null, 2)], { type: 'application/json' });
        const options = {
          method: 'POST',
          body: tokenBlob,
          mode: 'cors',  
          accepts: "application/json",         
          cache: 'default',
          contentType : "application/json",
          headers:{
            "Authorization":"Bearer "+ localStorage.getItem('accessToken')
          }
        }


        // return console.log(options)
        fetch(config.BASE_URL+'bids/submit', options)
        .then(async res => {
           
            const msg = await res.json(); 
            //console.log(msg)           
                alert(msg.description);
                if (msg.status){
                    window.location.href='/lands-and-layouts/plot/'+id;
                } 
              })
                .catch(err => {
                  alert(err)
                  console.log(err)
                  });
                }


        useEffect(()=>{
            getReserveAmount(id)
            return ()=>controller.abort()
        },[id])

        const getReserveAmount=()=>{
          fetch(config.BASE_URL+'lands/reserveamount/'+id,httpOptions("GET"))
          .then(response=>{
            response.json()
            .then(result=>{
              setReserveAmount(result);
            })
          })
          .catch(err =>{
            alert(err);            
          })
        }

    return (
		<>
			<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#biddingFormModal"><i className='bi bi-hand-index-thumb'></i> Make a Bid
			</button>
			<div className="modal fade" id="biddingFormModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="biddingFormModalLabel" aria-hidden="true">
		  <div className="modal-dialog modal-dialog-centered">
			<div className="modal-content">
			  <div className="modal-header">
				<h5 className="modal-title" id="biddingFormModalLabel">Make a Bid</h5>
				<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			  </div>
			  <div className="modal-body">
			  <Form onSubmit={submitBiddingForm} >
				<Form.Group className="mb-3" controlId="">				   
					<Form.Label>Bid Amount {bidstring} </Form.Label>
					<Form.Control type="number" placeholder="Enter bid amount" title='Bid Amount' onChange={e => setBid(e.target.value)} />
					{/* <Form.Text className="text-muted">
					We'll never share your email with anyone else.
					</Form.Text> */}
				</Form.Group>

				
				<div className="form-group mb-3">
					<div className="">        
					<label className='align-self-center'>
						<input type="checkbox"  name="AcceptTnc" id="tnc"  className='align-self-center' onChange={handleTncAcceptance} /> I accept  
						</label> <Link to='/tnc'> the Terms and Conditions</Link>
					</div>
				</div>
				<div className="row d-flex justify-content-between">
					<div className="col-sm-6 text-left">
					<Button variant="success" type="submit" >
						Submit Bid
					</Button>
					</div>                               
				</div>
			</Form>
			  </div>
			  <div className="modal-footer">
				<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
			  </div>
			</div>
		  </div>
		</div>
		</>
    )
}

export default BiddingModal
