import React, {useEffect, useState} from 'react'
import SingleLayout from './SingleLayout'
import Breadcrumbs from '../shared/Breadcrumbs'
import config from '../../config.json'

const Layouts = () => {
    const [layouts, updateLayouts]=useState([])
    const controller = new AbortController();
    const [loading, setLoading]=useState(false)

    useEffect(()=>{        
        getAllLandsAndLayouts()
        return () => controller.abort()
      },[]
    )

const getAllLandsAndLayouts=()=>{
    const options = {
        method: 'GET',
        // body: tokenBlob,
        mode: 'cors',  
        accepts: "application/json",         
        cache: 'default',
        contentType : "application/json",
        headers:{
          "Authorization":"Bearer "+ localStorage.getItem('accessToken')
        }
      }
        setLoading(true)
        fetch(config.BASE_URL+'lands/layouts-with-lands', options)
        .then(async response =>{
        const data = await response.json()   
           updateLayouts(data);
           setLoading(false)             
      }).catch(err=>
        err.message
      )        
    }

    return (
        <>
            <Breadcrumbs title="Layouts and Lands" />
            {layouts.length > 0 ? layouts.map(layout=>{
                return <SingleLayout key={layout.id} layoutImg={layout.image} dimensions={layout.dimenstions} location={layout.location} title={layout.title} description={layout.desription} plots={layout.plots} />
            }):<div className='container'>
            
                  <div className='alert alert-info my-5 py-5 text-center'><h5>{loading?"Loading...":"No layouts found in record"}</h5></div>
              </div>
            }
            
        </>
    )
}

export default Layouts
