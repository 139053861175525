import React from 'react'
import { Carousel } from 'react-bootstrap'


const Slider = () => {
    return (
        <Carousel>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="assets/img/slide/landscape1.jpg"
                    alt="First slide"
                    />
                    <Carousel.Caption>
                    <h3>Let BULLNET ePABS Show You... </h3>
                    <p>The Way Home</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="/assets/img/slide/landscape2.jpg"
                    alt="Second slide"
                    />

                    <Carousel.Caption>
                    <h3>Let BULLNET ePABS Show You...</h3>
                    <p>The Way To Your Dream House</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="assets/img/slide/layout.jpg"
                    alt="Third slide"
                    />

                    <Carousel.Caption>
                    <h3>Let BULLNET ePABS Show You...</h3>
                    <p>Your Dream Land</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
    )
}

export default Slider
