import React from 'react'
import Slider from './Slider'
import AboutIntro from './homepage-intros/AboutIntro'
import CallToActionBar from './shared/CallToActionBar'
import ServicesIntro from './homepage-intros/ServicesIntro'

const Home = () => {
    return (
        <div>
            <Slider />
            <AboutIntro />
            <CallToActionBar />
            <ServicesIntro />
        </div>
    )
}

export default Home
