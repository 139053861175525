import { useState, useEffect } from 'react'
import { Link, Redirect} from 'react-router-dom'
import Breadcrumbs from '../shared/Breadcrumbs'
import { isAuthenticated } from '../shared/repository'
import config from '../../config.json'
import { CModal } from '@coreui/react'
import { CModalBody } from '@coreui/react'
import {CModalHeader} from  '@coreui/react'
import {CModalTitle} from '@coreui/react/'


const PaymentList = () => {
    const [successfullPmts, updateSuccessfullPmts]=useState([])
    const [pendingPmts, updatePendingPmts]=useState([])
    const [failedPmts, updateFailedPmts]=useState([])
    const [modalVisible, setModalVisible]=useState(false)
    const [paymentDetails, updatePaymentDetails]=useState({
        "timeInitiated":"",
        "timeCompleted":"",
        "amount":"",
        "status":"Nill",
        "purpose":"Nill",
    });

    const controller= new AbortController()

    useEffect(() => {
        getPaymentInfo()
        return () => controller.abort();
    },[])

    const separatePaymentRecords=(records)=>{
        const successfulPayments=records.filter(r=>{
            return r.status=="Paid";
        })
        updateSuccessfullPmts(successfulPayments);
        

        const pendingPayments=records.filter(p=>{
            return p.status=="Pending";
        })

        updatePendingPmts(pendingPayments);

        const failedPayments=records.filter(f=>{
            return f.status != 'Pending' && f.status != "Paid";
        })

        updateFailedPmts(failedPayments);
    }

    const options = {
        method: 'GET',
        mode: 'cors',  
        accepts: "application/json",         
        cache: 'default',
        contentType : "application/json",
        headers:{
            "Authorization":"Bearer "+ localStorage.getItem('accessToken')
        }
    }

    const getPaymentInfo=()=>{
        if(!isAuthenticated()){
            return <Redirect to='/login' />
        }
       
        fetch(config.BASE_URL+'payments/my/payments/', options)
        .then(response =>{
          if(response.ok){
          response.json()
          .then(data => {
              
            if(data.noOfItems > 0)
            {               
               separatePaymentRecords(data.items) 
            }
          }).catch(err=>{
            alert(err)
          })
        }else{
            throw response.status
        }
        })
        .catch(error=>{
            alert("This is exception handling "+error);
        })
    }
    

    const showPendingPaymentDetails=(code)=>{
        getPendingPaymentDetails(code)
        setModalVisible(true)
    }

    const showSuccessfulPaymentDetails=(id)=>{
        getSuccessfulPaymentDetails(id);
        setModalVisible(true)
    }
    
    const getPendingPaymentDetails=(c)=>{
        fetch(`${config.BASE_URL}payments/pending/details/code/${c}`, options)
        .then(response =>{
          if(response.ok){
          response.json()
          .then(data => {              
            if(data !=null)
            {               
                updatePaymentDetails({
                    "timeInitiated":"N/A",
                    "timeCompleted":"N/A",
                    "amount":data.amount,
                    "status":"Pending",
                    "purpose":data.purpose,
                    "paymentCode":data.paymentCode
                })
            }
          }).catch(err=>{
            alert(err)
          })
        }else{
            throw response.status
        }
        })
        .catch(error=>{
            alert("This is exception handling "+error);
        })
    }

    const getSuccessfulPaymentDetails=(c)=>{
        fetch(`${config.BASE_URL}payments/successful/details/id/${c}`, options)
        .then(response =>{
          if(response.ok){
          response.json()
          .then(data => {              
            if(data !=null)
            {      
                updatePaymentDetails({
                    "timeInitiated":data.timeInitiated,
                    "timeCompleted":data.timeCompleted,
                    "amount":data.amount,
                    "status":data.status,
                    "purpose":data.purpose,
                    "currency":data.currency,
                    "paymentId":data.paymentId,
                    "receiptAvailable":true
                });
            }else{
                updatePaymentDetails({
                    "timeInitiated":"N/A",
                    "timeCompleted":"N/A",
                    "amount":"Unknown",
                    "status":"Unknown",
                    "purpose":"Unknown",
                    "paymentId":"N/A"
                });
            }
          }).catch(err=>{
            alert(err)
          })
        }else{
            throw response.status
        }
        })
        .catch(error=>{
            alert("This is exception handling "+error);
        })
    }

    return !isAuthenticated()?<Redirect to="/login" />:(

        <div>
            <Breadcrumbs title="My Payments" />
            <section id="pricing" className="pricing">
                <div className="container text-center">

                    <div className="row text-center">

                    <div className="col-md-4">
                        <div className="card">
                        <div className='card-header successful-payment-header'>
                            <p className='card-title text-center'>Successful Payments</p>
                        </div>
                        <div className='card-body'>
                        <h4 className='text-center'>{successfullPmts.length}</h4>
                        {successfullPmts.length > 0 ?
                        <table className='table table-striped'>
                            <thead>
                                <tr><th>Amount</th><th>Purpose</th><th>Time Paid</th></tr>
                            </thead>
                            <tbody>
                           {successfullPmts.map(s=>{
                                return <tr key={s.paymentId}>
                                <td>{s.currency}{s.amount} </td> 
                                <td>{s.purpose}</td>  
                                <td>{s.timeCompleted}</td>
                                <td title='See details'>
                                <button type="button" className="btn btn-primary"  onClick={()=>showSuccessfulPaymentDetails(s.paymentId)}><i className='bi bi-binoculars-fill'></i> View</button>
                                    </td> 
                           </tr>
                           })
                        }
                        </tbody>
                        </table>                        
                        :
                    <p className='lead text-center'>Nill</p>                        
        }
                        </div>
                        
                        </div>
                    </div>

                    <div className="col-md-4 mt-4 mt-md-0">
                        <div className="box featured">
                            <h3>Pending Payments</h3>
                        <h4>{pendingPmts.length}</h4>
                        {pendingPmts.length > 0 ?
                        <table className='table table-striped'>
                        <thead>
                            <tr><th>Amount</th><th>Purpose</th></tr>
                        </thead>
                       {pendingPmts.map(s=>{
                            return <tr key={s.paymentId}>
                            <td>{s.amount} </td> 
                            <td>{s.purpose}</td>  
                            <td title='See details'>
                            <button type="button" className="btn btn-primary" onClick={()=>showPendingPaymentDetails(s.paymentCode)}><i className='bi bi-binoculars-fill'></i> View</button>
                                </td> 
                       </tr>
                       })
                    }
                    </table>       
                        :
                        <p className='lead text-center'>Nill</p>
                    }
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
                        <div className="card">
                            <div className='card-header failed-payment-header'>
                            <p className='card-title text-center'>Failed Payments</p>
                            </div>   
                            <div className='card-body'>
                            <h4 className='text-center'>{failedPmts.length}</h4>
                        {failedPmts.length > 0 ?
                       <table className='table table-striped'>
                       <thead>
                           <tr><th>Amount</th><th>Purpose</th>Time Attempted<th></th></tr>
                       </thead>
                      {failedPmts.map(s=>{
                           return <tr key={s.paymentId}>
                           <td>{s.amount} </td> 
                           <td>{s.purpose}</td>  
                           <td>{s.timeInitiated}</td>
                           <td title='See details'>
                                <button type="button" className="btn btn-primary" onClick={()=>showSuccessfulPaymentDetails(s.paymentId)}><i className='bi bi-binoculars-fill'></i> View</button>
                            </td> 
                      </tr>
                      })
                   }
                   </table>       
                        :<p className='lead text-center'>Nill</p>}
                        </div>                     
                        
                        </div>
                    </div>

                    </div>

                </div>
            </section>
            <CModal alignment="center" scrollable visible={modalVisible} onClose={() => setModalVisible(false)}>
      <CModalHeader>
        <CModalTitle>Payment Details</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <dl className='row'>
                   <dt className='col-sm-6'>Purpose</dt>
                   <dd className='col-sm-6'>{paymentDetails.purpose}</dd>
                   <dt className='col-sm-6'>Amount</dt>
                   <dd className='col-sm-6'>{paymentDetails.currency}{paymentDetails.amount}</dd>
                   <dt className='col-sm-6'>Status</dt>
                   <dd className='col-sm-6'>{paymentDetails.status}</dd>
                   <dt className='col-sm-6'>Time Initiated</dt>
                   <dd className='col-sm-6'>{paymentDetails.timeInitiated}</dd>
                   <dt className='col-sm-6'>Time Completed</dt>
                   <dd className='col-sm-6'>{paymentDetails.timeCompleted}</dd>
                   {paymentDetails.paymentCode && <Link to={`/pay?code=${paymentDetails.paymentCode}`} className='btn btn-success'>Pay Now!</Link>}

                   {paymentDetails.status==='Paid' && <Link to={`/payments/receipt/${paymentDetails.paymentId}`} className="btn btn-success">Print/Download Receipt</Link>}
        </dl>
      </CModalBody>     
    </CModal>
        </div>
    )
}

export default PaymentList
