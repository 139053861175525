import React,{ useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom/cjs/react-router-dom.min'
import Breadcrumbs from '../shared/Breadcrumbs'
import { isAuthenticated } from '../shared/repository'
import BiddingModal from './BiddingModal'
import config from '../../config.json'

const LandDetails = () => {
    const {id} = useParams()
    const [landInfo, updateLandInfo]=useState({})
    const [landBids, updateLandBids]=useState({})

    useEffect(() => {
      const controller = new AbortController();
      getAndUpdateLandDetails(id);
      
      return () => {
        controller.abort();
      }
    }, [id])

    const getAndUpdateLandDetails=(id)=>{
      const options = {
        method: 'GET',
        mode: 'cors',  
        accepts: "application/json",         
        cache: 'default',
        contentType : "application/json",
        headers:{
          "Authorization":"Bearer "+ localStorage.getItem('accessToken')
        }
      }
     
      try{
        fetch(config.BASE_URL+'lands/'+id, options)
        .then(response => {
        if(response.ok){
        response.json()
        .then(data => {
          if(data.landInfo==null)
          {
              return alert("No information for the selected land!")
          }else{
            //console.log(data)
             updateLandInfo(data.landInfo);
             if(data.landBids != null){
              updateLandBids(data.landBids)
             }
          }
        }).catch(err=>{
          alert(err)
        })
      }else{
          throw response.status
      }
      })        
  }catch(error){
      alert("This is exception handling "+error)
  }
  }    

    return (
        <>
            <Breadcrumbs title="Plot Details" {...landInfo.label != null?' - '+landInfo.landName:''} />
            
            <section id="why-us" className="why-us section-bg">
            {/* <h3>This is the detail of the land with the ID of {id}</h3> */}

      <div className="container-fluid" data-aos="fade-up">

        <div className="row">

          <div className="col-lg-5 align-items-stretch video-box" data-aos="zoom-in" data-aos-delay="100">
           <Link to="/lands-and-layouts"><img src={`/assets/img/slide/${landInfo.imagePath}`} className="img-fluid" alt='' /></Link>
          </div>

          <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch">

            <div className="accordion-list">
                <div className="card">
                    <div className="card-body">
                        
            <div className="content">
              <h3>{landInfo.label} - {landInfo.layoutName}</h3>
              <p>
                {landInfo.description}
              </p>
            </div>
                    <ul className="list-group">
               
                <li className="list-group-item">
                    Lowest Acceptable Bid Amount : <strong>{landInfo.reserveAmount != null?landInfo.reserveAmount:'Undecided'}</strong>
                </li>

                <li className="list-group-item">
                  Bid Closes : <strong>{`${landInfo.biddingClosingDate != null?landInfo.biddingClosingDate:"Undecided"}`}</strong>
                </li>
                <li className="list-group-item">
                <div class="accordion" id="bidsAccordion">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="bids">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            Bids ({landBids.length}) - Click to see details
                        </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="bids" data-bs-parent="#bidsAccordion">
                          {landBids.length > 0 ?
                            <div className="accordion-body">
                            <ul className="list-group">
                            {landBids.map(bid =>
                               <li key={bid.id} className="list-group-item">Time of Bid : <strong>{bid.bidTime} </strong>- Amount Bidded : <strong>{bid.bidAmount}</strong> {bid.byCurrentUser? [`(Your bid)`]:''}</li>
                            )}
                            </ul>
                            </div>
                          :<h5>No bids yet on this land</h5>
                          }
                        </div>
                    </div>
                    </div>
                </li>
                {  (landInfo.biddingAvailability !== "Available")? <h5 className='text-center pt-4'>Bidding Closed</h5>               : isAuthenticated() ?
                <li className="list-group-item"><BiddingModal /></li> : 
                <li className="list-group-item"><Link to='/login' className='btn btn-primary'><i className='bi bi-hand-index-thumb'></i> Sign in to make a bid</Link></li>
}
              </ul>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
        </>
    )
}

export default LandDetails
