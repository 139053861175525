import config from '../../config.json'

    export function login(data) {

      const tokenBlob = new Blob([JSON.stringify({ email:data.email,password:data.password }, null, 2)], { type: 'application/json' });
      const options = {
          method: 'POST',
          body: tokenBlob,
          mode: 'cors',  
          accepts: "application/json",         
          cache: 'default',
          contentType : "application/json",
        }
      const loginPath=config.BASE_URL+'accounts/login';
      fetch(loginPath, options)
      .then(async r => {
        return await r.json()
          .then(accessToken => {
            if(!accessToken.status){
              alert(accessToken.description)
            }else{
              storeAuthToken(accessToken);
              window.location.href='/'
            } 
        });
    })      
    .catch(e=>{
        alert('Sorry, Error Occured: '+JSON.stringify(e));
    })
    }

    export const ensureAuthenticated=()=>{
      if(!isAuthenticated()){
        window.location.href='/login';
      }
    }

    export const httpOptions =(method,body=false)=> {
     
      return body?{
      method: method,
      body:body,
      mode: 'cors',  
      accepts: "application/json",         
      cache: 'default',
      contentType : "application/json",
      headers:{
          "Authorization":"Bearer "+ localStorage.getItem('accessToken')
        }
      }:{
        method: method,
        mode: 'cors',  
        accepts: "application/json",         
        cache: 'default',
        contentType : "application/json",
        headers:{
            "Authorization":"Bearer "+ localStorage.getItem('accessToken')
          }
        }
    }

    export function isAuthenticated(){        
        if(localStorage.getItem('accessTokenExpiration') !==null 
        && localStorage.getItem('accessTokenExpiration') !=='' 
        && new Date() < new Date(localStorage.getItem('accessTokenExpiration'))
        ){
          return true;
        }

        clearAuthTokens();
        
        return false;
 }

  const clearAuthTokens=()=>{
    localStorage.getItem("accessToken") != null && localStorage.removeItem("accessToken");
        localStorage.getItem("accessTokenExpiration") != null && localStorage.removeItem("accessTokenExpiration")
        localStorage.getItem('user') != null && localStorage.removeItem('user')
        return;
  }

 export const logout=() =>{

    if(isAuthenticated()){  
        const bearer = 'Bearer ' + localStorage.getItem('accessToken');  
        const options = {
            method: 'POST',
            mode: 'cors',  
            accepts: "application/json",         
            cache: 'default',
            contentType : "application/json",
            headers:{
                'Authorization':bearer,
                'Content-Type': 'application/json'
            }
        }

        fetch(config.BASE_URL+'accounts/logout',options)
        .then((response) => {
          if(!response.ok){
            clearAuthTokens();
            window.location.href='/'
          }
            return response.json()
          })
          .then(result => {
            if(result.status){
              clearAuthTokens();
                alert(result.description)
                window.location.href='/login';
            }
          })
          .catch((err) => {
            alert(err)
          })
    }else{
        window.location.href='/login';
    }
}

    export const facebookResponse = (response) => {
        // console.log(response);
        const tokenBlob = new Blob([JSON.stringify({ TokenId: response.accessToken }, null, 2)], { type: 'application/json' });
        const options = {
          method: 'POST',
          body: tokenBlob,
          mode: 'cors',  
          accepts: "application/json",         
          cache: 'default',
          contentType : "application/json",
        }
        //console.log(response.access_token);
        fetch(config.FACEBOOK_AUTH_CALLBACK_URL, options)
          .then(r => {
            r.json().then(accessToken => {               
               storeAuthToken(accessToken)
                window.location.href='/';
            });
        })      
        .catch(e=>{
            console.log(e)
        })
    };

    export const onFailure = (error) => {
        console.log(error);
        alert("Error occured and has been logged in the console")
      };

    export const googleResponse = (response) => {       
        
        const tokenBlob = new Blob([JSON.stringify({ TokenId: response.tokenId }, null, 2)], { type: 'application/json' });
        const options = {
          method: 'POST',
          body: tokenBlob,
          mode: 'cors',  
          accepts: "application/json",         
          cache: 'default',
          contentType : "application/json",
        }

        fetch(config.GOOGLE_AUTH_CALLBACK_URL, options)
          .then(r => {
            r.json().then(accessToken => {
              storeAuthToken(accessToken)
              window.location.href='/';
              });
        })      
        .catch(e=>{
            console.log(e)
        })
          
      }

      export const storeAuthToken=(tokenString) =>
      {
          localStorage.setItem('accessToken',tokenString.token);
          localStorage.setItem('accessTokenExpiration', new Date(tokenString.expiration));
          localStorage.setItem('user',tokenString.name);
          return;
      }