import React from 'react'

const ServicesIntro = () => {
    return (
        <section id="services" className="services">
      <div className="container">

        <div className="row">
          <div className="col-md-6">
            <div className="icon-box">
              <i className="bi bi-briefcase"></i>
              <h4><a href="#">Website</a></h4>
              <p>Website with web pages that address the basic questions of potential visitors with enough information to enable them make buying decisions.</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i className="bi bi-card-checklist"></i>
              <h4><a href="#">Plot Allocations and Selection for Auction Bids</a></h4>
              <p>Bidders can view the entire layout scheme and the allocated plots in the scheme and be able to click on any plot of their choice to bid on and this can be multiple plots to extend their chances but not more than 3 plots at a time.</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i className="bi bi-pen"></i>
              <h4><a href="#">Registration</a></h4>
              <p>Bidders can get on the website and immediately register with our simple KYC form where they will fill in manually all their social media platform and information for us to do a verification or just register with the link to any of these platforms and get the information we need automatically and programmatically.</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i className="bi bi-bar-chart"></i>
              <h4><a href="#">Bid Ranking</a></h4>
              <p>When a user bids, the timestamp and amount determines his position on that bid whether he is verified or not. If will however lose that position if he is not verified.</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i className="bi bi-speedometer"></i>
              <h4><a href="#">User Dashboard</a></h4>
              <p>Each registered user will have a dashboard where he/she can view his/her records, update his/her profile and view his/her bids, and other data that are personal to him/her.</p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i className="bi bi-cash"></i>
              <h4><a href="#">Payment Records and Management</a></h4>
              <p>A payment gateway is provided on the platform to receive payments from users through their debit/credit cards, bank transfers or any other electronic means of payment</p>
            </div>
          </div>
        </div>

      </div>
    </section>
    )
}

export default ServicesIntro
