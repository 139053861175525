import React from 'react'
import SingleLayoutMainContent from './SingleLayoutMainContent'
import SingleLayoutSidebar from './SingleLayoutSidebar'

const SingleLayout = (props) => {
    return (
        <>
            <section id="blog" class="blog">
      <div class="container" data-aos="fade-up">

        <div class="row">

          <div class="col-lg-8 entries">

           <SingleLayoutMainContent layoutImg={props.layoutImg} title={props.title} dimensions={props.dimensions} location={props.location} description={props.description} />
        </div>
          <div class="col-lg-4">

            <SingleLayoutSidebar plots={props.plots} layoutImg={props.layoutImg} />
          </div>
        </div>
      </div>
            </section>
            </>
    )
}

export default SingleLayout
