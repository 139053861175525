import React from 'react'

const SingleLayoutMainContent = (props) => {
    return (
        <article class="entry entry-single">

        <div class="entry-img">
          <img src={`/assets/img/slide/${props.layoutImg}`} alt="" class="img-fluid" usemap="#image-map" /> 
          <map name="image-map">
    <area target="_self" alt="Plot H1" title="Plot H1" href="/lands-and-layouts/plot/1" coords="116,472,74,471,74,310,180,311,180,455,152,454,147,439,116,438" shape="poly" />
    <area target="_self" alt="Plot H2" title="Plot H2" href="/lands-and-layouts/plot/2" coords="72,171,162,173,178,191,178,299,76,299" shape="poly" />
    <area target="_self" alt="Plot H3" title="Plot H3" href="/lands-and-layouts/plot/3" coords="73,35,180,36,180,144,164,162,73,163" shape="poly" />
    <area target="_self" alt="Plot H4" title="Plot H4" href="/lands-and-layouts/plot/4" coords="667,163,795,165,793,35,654,35,653,142" shape="poly" />
    <area target="_self" alt="Plot H5" title="Plot H5" href="/lands-and-layouts/plot/5" coords="654,292,776,292,774,172,670,172,654,193" shape="poly" />
    <area target="_self" alt="Plot L1" title="Plot L1" href="/lands-and-layouts/plot/6" coords="188,304,284,400" shape="rect" />
    <area target="_self" alt="Plot L2" title="Plot L2" href="/lands-and-layouts/plot/7" coords="294,303,392,403" shape="rect" />
    <area target="_self" alt="Plot L3" title="Plot L3" href="/lands-and-layouts/plot/8" coords="444,402,541,302" shape="rect" />
    <area target="_self" alt="Plot L4" title="Plot L4" href="/lands-and-layouts/plot/9" coords="549,303,647,403" shape="rect" />
    <area target="_self" alt="Plot L5" title="Plot L5" href="/lands-and-layouts/plot/10" coords="549,292,647,196" shape="rect" />
    <area target="_self" alt="Plot L6" title="Plot L6" href="/lands-and-layouts/plot/11" coords="444,295,540,197" shape="rect" />
    <area target="_self" alt="Plot L7" title="Plot L7" href="/lands-and-layouts/plot/12" coords="393,294,295,194" shape="rect" />
    <area target="_self" alt="Plot L8" title="Plot L8" href="/lands-and-layouts/plot/13" coords="283,196,189,295" shape="rect" />
    <area target="_self" alt="Plot L9" title="Plot L9" href="/lands-and-layouts/plot/14" coords="188,35,266,140" shape="rect" />
    <area target="_self" alt="Plot L10" title="Plot L10" href="/lands-and-layouts/plot/15" coords="278,35,360,142" shape="rect" />
    <area target="_self" alt="Plot L11" title="Plot L11" href="/lands-and-layouts/plot/16" coords="374,36,456,140" shape="rect" />
    <area target="_self" alt="Plot L12" title="Plot L12" href="/lands-and-layouts/plot/17" coords="471,34,552,141" shape="rect" />
    <area target="_self" alt="Plot L13" title="Plot L13" href="/lands-and-layouts/plot/18" coords="568,36,646,138" shape="rect" />
</map>
        </div>

        <h2 class="entry-title">
          <a href="#">{props.title}</a>
        </h2>

        <div class="entry-meta">
          <ul>
            <li class="d-flex align-items-center"><i class="bi bi-geo-alt"></i> <a href="#">{props.location}</a></li>
            <li class="d-flex align-items-center"><i class="bi bi-aspect-ratio"></i> <a href="#"><span>{props.dimensions}</span></a></li>
          </ul>
        </div>

        <div class="entry-content">
          {props.description}
        </div>
      </article>
    )
}

export default SingleLayoutMainContent
