import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer id="footer">
        <div className="footer-top">
            <div className="container">
                <div className="row">
                    
          <div className="col-lg-3 col-md-6">
            <div className="footer-info">
              {/* <h3>ePABS</h3> */}
              <img src="/assets/images/logo-nobg-100.png" width='130' alt="ePABS platform" class="img-fluid" />
              <p>
                Lekki Phase 1 <br />
                Lagos, Nigeria<br /><br />
                <strong>Phone:</strong> +234 803 1234 55<br />
                <strong>Email:</strong> info@epabs.com<br />
              </p>
              <div className="social-links mt-3">
                <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
                <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
                <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
              </div>
            </div>
          </div>
                <div className="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <Link to="/">Home</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="/the-concept">The Concept</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="/how-it-works">How It Works</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="/faq">FAQ</Link></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
            </ul>
          </div>
                <div className="col-lg-3 col-md-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Estate Development</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Estate Management</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Project Management</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Building Construction</a></li>
            </ul>
          </div>
                <div className="col-lg-4 col-md-6 footer-newsletter">
            <h4>Our Newsletter</h4>
            <p>Once in a while, we send valuable information in the form of promotions, offers, special discounts and industry news. To get these updates when they happen, subscribe below</p>
            <form action="" method="post">
              <input type="email" name="email" /><input type="submit" value="Subscribe" />
            </form>

          </div>
                </div>
            </div>
        </div>
        <div className="container">
                    <div className="copyright">
                        &copy;2021 - <strong><span>ePABS</span></strong>. All Rights Reserved
                    </div>
                    {/* <div className="credits">Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                    </div> */}
                </div>
        </footer>
    )
}

export default Footer
