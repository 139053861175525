import { Redirect } from 'react-router-dom'
import Breadcrumbs from '../shared/Breadcrumbs'
import { useState,useEffect } from 'react'
import {isAuthenticated, httpOptions} from '../shared/repository'
import config from '../../config.json'
import { useLocation } from 'react-router-dom'


const InitiatePayment=()=>{

    const queryParameters=new URLSearchParams(useLocation().search);
    const paymentCode=queryParameters.get('code')
    const [fxRates,updateFxRates]=useState({"usd":480,"gbp":570})

    const [code,setCode]=useState(paymentCode);
    const [currency, setCurrency]=useState("NGN");
    const [paymentData,updatePaymentData]=useState();
    
    // const updateCurrency=(e)=>{
    //     setCurrency(e.target.value);        
    // }

    const submitPaymentCode=()=>{
        const tokenBlob= new Blob([JSON.stringify({code,currencyCode:currency}, null, 2)], { type: 'application/json' });

        const options = {
            method: 'POST',
            body: tokenBlob,
            mode: 'cors',  
            accepts: "application/json",         
            cache: 'default',
            contentType : "application/json",
            headers:{
              "Authorization":"Bearer "+ localStorage.getItem('accessToken')
            }
          }
         
          try{
          fetch(config.BASE_URL+'payments/initiate', options)
          .then(response =>{
            if(response.ok){
            response.json()
            .then(data => {
                
              if(!data.status)
              {
                  return alert(data.status)
              }else{
                  //console.log(data)
                 updatePaymentData(data);
                 //console.log(paymentData)
              }
            }).catch(err=>{
              alert(err)
            })
          }else{
              throw response.status
          }
          })        
      }catch(error){
          alert("This is exception handling "+error)
          console.log(error)
      }
    }
    
    const getPaymentDetailsWithCode=(code)=>{
        fetch(config.BASE_URL + `payments/expectedwithfx/${code}`,httpOptions('GET'))
        .then(response=>{
            response.json()
            .then(result=>{
                updateFxRates(result);
            })
        })
        .catch(err=>{
            alert(err)
            console.log(err)
        })
    }

        const controller=new AbortController()

    useEffect(()=>{
        getPaymentDetailsWithCode(code)
        return ()=>controller.abort();
    },[paymentCode])

    return(
        (!isAuthenticated())?<Redirect to='/login' /> 
        :
        <>
            <Breadcrumbs title="Make Payment" />
            <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-8 offset-md-2'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h5>Make Payment</h5>
                                    {paymentData == null ?
                                    <form>
                                        <div className='form-group my-4' >
                                            <label for="code"><h6>Payment Code</h6></label>
                                            <input 
                                                type="text" 
                                                name='code'
                                                className='form-control'
                                                placeholder='Enter your payment code here'
                                                value={code}
                                                onChange={(e)=>setCode(e.target.value)}
                                                id="code"
                                                />
                                        </div>
                                        {fxRates.purpose != null ?
                                        <div className='form-group my-4 d-flex justify-content-center' >
                                            <strong>Payment is for: {fxRates.purpose}</strong> 
                                        </div> :''
                                        }
                                        <div className='form-group my-4'>
                                            <h5>Applying Exhange Rates </h5>
                                            <p>1 USD : {`NGN ${fxRates.usd}` || "Unavailable"}</p>
                                            <p>1 GBP : {`NGN ${fxRates.gbp}` || "Unavailable"}</p>
                                            <p><strong>Amount to pay in NGN </strong> : {fxRates.amountString || 'N/A' }</p>
                                            <p><strong>Amount to pay in USD </strong> : USD {fxRates.amount != null ?(fxRates.amount/fxRates.usd).toPrecision(3) : 'N/A' }</p>                                            
                                            <p><strong>Amount to pay in GBP </strong> : GBP {(fxRates.amount/fxRates.gbp).toPrecision(3) || 'N/A' }</p>
                                        </div>
                                        <div className='form-group my-4'>
                                            <label for="currency"><h6>Choose Currency for Payment</h6></label>
                                            <select name='currency' onChange={(e)=>setCurrency(e.target.value)}
                                                className='form-control' id="currency">
                                                 <option value="NGN">NGN</option> 
                                                 <option value="USD">USD</option>
                                                 <option value="GBP">GBP</option> 
                                                </select>
                                        </div>
                                        <div className='form-group d-flex justify-content-center my-3'>
                                            <button className='btn btn-success btn-lg' onClick={submitPaymentCode} type='button'>Make Payment</button>
                                        </div>
                                    </form> :
                                    <>
                                    <div className='form-group'>Payment is for {paymentData.purpose} by {paymentData.payer} and the payment amount is {currency} {paymentData.amount}</div>
                                        
                                        <div className='form-group d-flex justify-content-center flex-row'>
                                        <a href={paymentData.paymentLink} className='btn btn-success'><i class="bi bi-arrow-right-circle"></i> Proceed with Payment</a>
                                        </div>
                                        </>
}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>  
    )  
}

export default InitiatePayment