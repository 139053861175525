import { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import  Breadcrumbs from '../shared/Breadcrumbs';
import config from '../../config.json'
import {httpOptions} from '../../components/shared/repository'
import { useReactToPrint } from 'react-to-print';
import ReceiptBody from './ReceiptBody';

const Receipt2 =()=> {
  const [receiptData,setReceiptData]=useState({})
  const [loading, setLoading]=useState(false)
  const {id}=useParams()
  const componentRef=useRef()
  const handlePrint=useReactToPrint({
    content:()=>componentRef.current
  })
  
  const controller= new AbortController()
    
    useEffect(
      ()=>{
        getReceiptData()
        return ()=>controller.abort()
      },[id]
    )
  
    const getReceiptData=()=>{ 
      setLoading(true)   
            fetch(config.BASE_URL+'payments/receipt/'+id, httpOptions("GET"))
            .then(async response =>{
              if(response.ok){
                return await response.json()
                .then(data =>{
                  setReceiptData(data);
                  setLoading(false);
                })
              }
            })
            .catch(err=>{
              console.log(err)
              alert(err)
              setLoading(false)
            })
            
    }
    return <>
      <Breadcrumbs title="Payment Receipt" />
      <section id="receiptSection" className="pricing">
      <div className='container'>{
        loading ? <h3 className='text-center'>Loading...</h3> :
        <div className='row'>      
        <ReceiptBody ref={componentRef} receiptData={receiptData} />
        <div className='col-md-6 offset-md-3 d-flex justify-content-between' id="download-btn">
          <button className='btn btn-primary ' onClick={handlePrint}>Print or Download</button> 
          <Link to="/my/payments" className='btn btn-success'>Continue</Link>
          </div>     
        </div>
        }
        </div> 
      </section>
    </>
  }

export default Receipt2;