import React from 'react'
import {Form, Button} from 'react-bootstrap'
import { Link, Redirect} from 'react-router-dom'
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import config from '../config.json';
import Breadcrumbs from './shared/Breadcrumbs';
import { useState } from 'react';
import { googleResponse, facebookResponse, onFailure, isAuthenticated,login} from './shared/repository';

//set HTTPS=true&&npm start


const Login = () => {
   
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('');
    const [loading,setLoading]=useState(false);

    if(isAuthenticated()) return <Redirect to='/' />
    
       const submitLoginForm=(e)=>{
        e.preventDefault();
        setLoading(true)
        const data={email,password}
        login(data)
        setLoading(false)
    }

    return (
        <main id="main">
           <Breadcrumbs title="Login" />

            <section id="about" class="about pb-1">
            <div Name="container">

                <div className="row content">
                <div className="col-10 offset-1 col-md-4 offset-md-4">
                    <div className='card'>
                        <div className='card-body'>
                        <Form onSubmit={submitLoginForm} >
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" title='Email' onChange={e => setEmail(e.target.value)} />                                
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" title='Password' onChange={e => setPassword(e.target.value)} />
                            </Form.Group>
                            
                            <div className="row d-flex justify-content-between">
                                { loading ? <h5 className='text-center'>Loading...</h5>: <>
                                <div className="col-sm-6 text-left">
                                <Button variant="warning" type="submit" >
                                    {loading?"Loading...":"Login"}
                                </Button>
                                </div>
                                <div className="col-sm-6 text-end">
                                    <Link to='/forgot-password'> I Forgot My Password</Link>
                                </div>
                                </>
                                }
                            </div>
                        </Form>
                        </div>
                    </div>
        
        </div>
        </div>
        </div>
        </section>
        <section className="pt-5">
        <div Name="container">

        <div className="row content">
        <div className="col-md-4 offset-md-4 text-center">
            { loading ? <h5 className='text-center'>Loading...</h5> :
            <>
            <FacebookLogin
                appId={config.FACEBOOK_APP_ID}
                autoLoad={false}
                fields="name,email,picture"
                // scope="public_profile"
                textButton="Login"
                size='small'
                icon='bi bi-facebook'
                callback={facebookResponse}
                onFailure={onFailure}
                style={{marginRight:'20px'}}
                onClick={()=>setLoading(true)}

            />  

            <GoogleLogin
                clientId={config.GOOGLE_CLIENT_ID}
                buttonText="Login"
                onSuccess={googleResponse}
                onFailure={onFailure}
                onClick={()=>setLoading(true)}
            />
            </>
        }
            </div>
            </div>
            </div>
        </section>
        </main>
    )
}

export default Login
