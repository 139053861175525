import {useState, useEffect } from 'react'
import { Form,Col,Row } from 'react-bootstrap'
import { Link, Redirect,useHistory } from 'react-router-dom'
import Breadcrumbs from '../shared/Breadcrumbs'
import config from '../../config.json'
import { isAuthenticated } from '../shared/repository'

const EditProfile = () => {

const history=useHistory();
const controller = new AbortController();

// const [socialMediaHandles,updateSocialMediaHandles]= useState({
    
// })

    // const [surname, setSurname] = useState('')
    // const [othernames,setOthernames]=useState('')
    // const [email, setEmail]=useState('');
    // const [confirmEmail,setConfirmEmail]=useState('')
    // const [phoneNumber,setPhoneNumber]=useState('')
    // const [confirmPhoneNumber,setConfirmPhoneNumber]=useState('')
    // const [facebook,setFacebook]=useState('')
    // const [twitter,setTwitter]=useState('');

    useEffect(() => {
        fetchProfileInfo()
        return () => controller.abort()
    }, [])

    const [userData, setUserData]=useState();

    const updateUserData=(event) =>{
        setUserData({...userData,[event.target.name]:event.target.value})
    }

    const submitUpdateForm = () => {

        if(userData.email != userData.confirmEmail)
        {
            alert("Email and Confirm Email have to match")
        }else if(userData.phoneNumber !== userData.confirmPhoneNumber)
        {
            alert("Phone number and Confirm phone have to match")
        } else {
            //const userData={email,phoneNumber,surname,othernames,facebook,twitter}
        const tokenBlob= new Blob([JSON.stringify(userData, null, 2)], { type: 'application/json' });
        const options = {
          method: 'POST',
          body: tokenBlob,
          mode: 'cors',  
          accepts: "application/json",         
          cache: 'default',
          contentType : "application/json",
          headers:{
            "Authorization":"Bearer "+ localStorage.getItem('accessToken')
            }
        }
    // console.log(userData);
    // alert(userData.socialMediaHandles.Facebook)
        fetch(config.BASE_URL+'user/update-profile', options)
        .then(res => {
            res.json()
            .then(response => {
                if(response.status)
                {
                    alert(response.description)
                    history.push('/my-profile');
                    } else {
                        alert(response.description);
                    }               
                });
            })
            .catch(err => alert(err));
        }
    }

    // function myChangeHandler(event) {
        
    //         updateUserData({...userData,[event.target.name]: event.target.value});
    // }

    const fetchProfileInfo=() => {
        if(!isAuthenticated())
        {         
            return <Redirect to='/login' />
        }

        const options = {
            method: 'GET',
            // body: tokenBlob,
            mode: 'cors',  
            accepts: "application/json",         
            cache: 'default',
            contentType : "application/json",
            headers:{
            "Authorization":"Bearer "+ localStorage.getItem('accessToken')
            }
        }

      fetch(config.BASE_URL+'user/my-profile', options)
      .then(response =>{
        // console.log(response)
        // if(response.ok){
        response.json()
        .then(data => {
          if(!data.status)
          {
           alert(data.description)
          }else{
            setUserData({
               "id":data.id,
               "email":data.email,
               "confirmEmail":data.email,
               "phoneNumber":data.phoneNumber,
               "confirmPhoneNumber":data.phoneNumber,
               "surname":data.surname,
               "othernames":data.othernames,
               "facebook":data.socialMediaHandles['Facebook'] ||'',
               "twitter":data.socialMediaHandles['Twitter'] || ''
           })
        //    console.log(userData)
          }
        })     
      })
      .catch(err => alert(err));
      }

 

        return (
            (!isAuthenticated())?<Redirect to='/login' />:
            <div>
                <Breadcrumbs title="Editing My Profile" />
                <section id="services2" className="services2">
                    <div className="container" data-aos="fade-up">
                        <div className="row">
                            <div className="section-title">
                                <h2>My Current Profile Details</h2>
                                <p>Updating Existing Profile Information</p>
                            </div>       
                        </div>
            <div className='row'>
                <div className='col-md-7'>
                    <h5>Biodata</h5>
                    
                    <Form onSubmit={(e)=>submitUpdateForm(e)} >
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control name="email" type="email" placeholder="Enter email" required value={userData?.email ||''} onChange={updateUserData} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridConfirmEmail">
                                <Form.Label>Confirm Email</Form.Label>
                                <Form.Control 
                                    name="confirmEmail" 
                                    type="email" 
                                    placeholder="Confirm your email" 
                                    required 
                                    value={userData?.confirmEmail || ''}
                                    onChange={updateUserData} />
                            </Form.Group>    
                        </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridPhone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control 
                                    name="phoneNumber" 
                                    type="text" 
                                    placeholder="Enter phone" 
                                    required 
                                    value={userData?.phoneNumber ||''}
                                    onChange={updateUserData} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridConfirmPhone">
                                <Form.Label>Confirm Phone</Form.Label>
                                <Form.Control 
                                    name="confirmPhoneNumber" 
                                    type="number" 
                                    placeholder="Confirm your phone number" 
                                    required 
                                    value={userData?.confirmPhoneNumber || ''}
                                    onChange={updateUserData} />
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridSurname">
                                <Form.Label>Surname</Form.Label>
                                <Form.Control 
                                    name="surname" 
                                    type="text" 
                                    placeholder="Enter your surname" 
                                    required 
                                    onChange={updateUserData} 
                                    value={userData?.surname || ''} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridOthernames">
                                <Form.Label>Other Names</Form.Label>
                                <Form.Control 
                                    name="othernames" 
                                    type="text" 
                                    placeholder="Enter your other names" 
                                    required 
                                    onChange={updateUserData} 
                                    value={userData?.othernames || ''} 
                                    />
                                </Form.Group>
                            </Row>
                                                               
                            </Form>            
                </div>
                <div className='col-md-5 d-flex justify-content-end'>
                   
                    <ul className='list-group'>
                    <h5>Social Medial Handles</h5>

                    <Row className="mb-3">    
                                <Form.Group as={Col} controlId="formGridFacebook">
                                <Form.Label>Facebook profile address</Form.Label>
                                <Form.Control 
                                    name="facebook" 
                                    type="text" 
                                    placeholder="e.g. www.facebook.com/you-facebook-username" 
                                    onChange={updateUserData} 
                                    value={userData?.facebook ||''}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">    
                                <Form.Group as={Col} controlId="formGridTwitter">
                                <Form.Label>Twitter handle</Form.Label>
                                <Form.Control name="twitter" type="text" placeholder="e.g. www.twitter.com/you-twitter-username" onChange={updateUserData} value={userData?.twitter ||''} />
                                </Form.Group>
                            </Row>                            
                    </ul>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-md-4 text-center'>
                <Link to='/my-profile' className='btn btn-lg btn-warning'><i class="bi bi-skip-backward-fill"></i> Back to Profile</Link> <button className='btn btn-lg btn-secondary' onClick={submitUpdateForm}> Update <i class="bi bi-skip-forward-fill"></i></button>
                </div>
            </div>
            </div>
        </section>
        </div>
    )
}

export default EditProfile
