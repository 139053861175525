import React from 'react'
import Breadcrumbs from './shared/Breadcrumbs'
import { Link } from 'react-router-dom'

const HowItWorks = () => {
    return (
        <main id="main">

            <Breadcrumbs title="How It Works" />

           <section id="features" className="features">
                <div className="container">

                    <div className="section-title">
                    <h2>Steps</h2>
                    <p>How to participate</p>
                    </div>

                    <div className="row">
                    <div className="col-lg-3">
                        <ul className="nav nav-tabs flex-column">
                        <li className="nav-item">
                            <a className="nav-link active show" data-bs-toggle="tab" href="#tab-1">Step 1 : Sign up</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="tab" href="#tab-2">Step 2 : View plots</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="tab" href="#tab-3">Step 3 : Bid for plot</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="tab" href="#tab-4">Step 4 : Get result</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="tab" href="#tab-5">Step 5 : Make Payment</a>
                        </li>
                        </ul>
                    </div>
                    <div className="col-lg-9 mt-4 mt-lg-0">
                        <div className="tab-content">
                        <div className="tab-pane active show" id="tab-1">
                            <div className="row">
                            <div class="col-lg-8 details order-2 order-lg-1">
                                <h3>Sign Up</h3>
                                <p>Register by clicking on <Link to="/signup">JOIN</Link> link and fill in your details on the form provided or use your Facebook or Google profile to register.</p>                           
                            </div>
                            <div className="col-lg-4 text-center order-1 order-lg-2">
                                <img src="assets/img/features-1.png" alt="" className="img-fluid" />
                            </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-2">
                            <div className="row">
                            <div class="col-lg-8 details order-2 order-lg-1">
                                <h3>View Plots</h3>
                                
                                <p>Discover our catalogue of choice landed properties on our platform and pick as many options as you wish to bid on.</p>
                            </div>
                            <div className="col-lg-4 text-center order-1 order-lg-2">
                                <img src="assets/img/features-2.png" alt="" className="img-fluid" />
                            </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-3">
                            <div className="row">
                            <div className="col-lg-8 details order-2 order-lg-1">
                                <h3>Bid for Plots</h3>                          
                                <p>Click on the Plot / House of your choice in any of the exciting Estate Layouts on our platform and follow the prompts to start bidding.</p>
                            </div>
                            <div className="col-lg-4 text-center order-1 order-lg-2">
                                <img src="assets/img/features-3.png" alt="" className="img-fluid" />
                            </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-4">
                            <div className="row">
                            <div className="col-lg-8 details order-2 order-lg-1">
                                <h3>Get Result</h3>
                                
                                <p>You will get email and SMS notifications when you make a bid, when any bid is made on the property and when you win the bid for the property.</p>
                            </div>
                            <div className="col-lg-4 text-center order-1 order-lg-2">
                                <img src="assets/img/features-4.png" alt="" className="img-fluid" />
                            </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-5">
                            <div className="row">
                            <div className="col-lg-8 details order-2 order-lg-1">
                                <h3>Make Payment</h3>
                               
                                <p>Click on PAY to make payment of the deposit and the balance for the property directly on the platform using e-payment solutions or making deposits at any of our banks.</p>
                            </div>
                            <div className="col-lg-4 text-center order-1 order-lg-2">
                                <img src="assets/img/features-5.png" alt="" className="img-fluid" />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                </div>
                </section> 
        </main>
    )
}

export default HowItWorks
