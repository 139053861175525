import React,{useState,useEffect} from 'react'
import { useLocation} from 'react-router-dom'
import Breadcrumbs from '../shared/Breadcrumbs'
import config from '../../config.json'


const PaymentStatus = () => {
    // const initialStatus=useLocation().search
    const queryParameters=new URLSearchParams(useLocation().search);
    // const initialStatus = queryParameters.get('status');
    const tx_ref=queryParameters.get('tx_ref');
    const transaction_id=queryParameters.get('transaction_id');
    const transaction_status=queryParameters.get('status');


    const [statusReport,updateStatusReport]=useState("loading...")
    const [situation,updateSituation]=useState('info')
    const controller = new AbortController();

    // const tokenBlob= new Blob([JSON.stringify({tx_ref,transaction_id}, null, 2)], { type: 'application/json' });

        const options = {
            method: 'GET',
            //body: tokenBlob,
            mode: 'cors',  
            accepts: "application/json",         
            cache: 'default',
            contentType : "application/json"
        }

        useEffect(() => {
                confirmPaymentStatus();
            return () => controller.abort();
        }, [transaction_id,tx_ref,transaction_status])


const confirmPaymentStatus=()=>{
    if(transaction_status==='success' || transaction_status==='successful'){
        fetch(config.BASE_URL+'payments/status/'+tx_ref+'/'+transaction_id, options)
        .then(response =>{
            if(response.ok){
                response.json()
                .then(data => {
                    if(!data.status)
                    {
                        alert(data.description)
                        updateSituation('danger')
                        updateStatusReport(data.message)
                    }else{
                    updateStatusReport("Payment succeefully received. Thank you");
                    updateSituation('success')
                    }
                    }).catch(err=>{
                        alert(err)
                    })
                }else{
                    throw response.status
                }
            })
            .catch(error=>{
                alert("Sorry, something went wrong technically! "+error);
            })
        }else{
            alert("Payment was NOT successful. Please retry")
            updateStatusReport("Payment failed")
            updateSituation('danger')
        }
    }

    return (
        <div>
            <Breadcrumbs title="Payment Status" />
            <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 offset-md-3'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className={"alert alert-"+situation}>
                                    {statusReport}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </section>
        </div>
    )
}

export default PaymentStatus
