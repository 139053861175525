import config from '../../config.json'
import { Redirect,Link} from 'react-router-dom/cjs/react-router-dom.min'
import { isAuthenticated } from '../shared/repository'
import { useState,useEffect } from 'react'
import Breadcrumbs from '../shared/Breadcrumbs'

const Mybids = () => {

    const [bids, updateBids]=useState(null)
    const controller = new AbortController();
    const [loading, setLoading]=useState(false)

    useEffect(()=>{
        getUserBids()
        return () => controller.abort();
    },[])

    const getUserBids=()=>{

    if(!isAuthenticated())
        {         
          return <Redirect to='/login' />
        } 

        setLoading(true)

        const options = {
          method: 'GET',
          // body: tokenBlob,
          mode: 'cors',  
          accepts: "application/json",         
          cache: 'default',
          contentType : "application/json",
          headers:{
            "Authorization":"Bearer "+ localStorage.getItem('accessToken')
          }
        }
       
        try{
         
          fetch(config.BASE_URL+'bids/my-bids', options)
          .then(response =>{
            if(response.ok){
            response.json()
            .then(data => {
              if(!data.status)
              {
                setLoading(false)
                return alert(data.status)                
            }else{
               updateBids(data.bids);
               setLoading(false)
            }
          }).catch(err=>{
            alert(err)
          })
        }else{
            throw response.status
        }
        })        
      }catch(error){
        console.log(error)
        alert(error)
      }
    }
    return (
        bids == null ? <div className='alert alert-info'><i className='bi bi-info'></i> You have no bids in record</div> :
        <>
            <Breadcrumbs title="My Bids" />
            <section id="services2" className="services2">
            <div className="container" data-aos="fade-up">
      
      
              <div className="row">
                <div className="section-title">
                <h2>My Account Summary</h2>
                <p>Summary of my account</p>
              </div>
             
              </div>
              {
                loading ? <h4 className="d-flex justify-content-center">Loading...</h4> :
            <table className='table table-striped table-hover'>
                <thead>
                    <tr>
                        <th>Time of Bid (in GMT)</th>
                        <th>Bid Amount</th>
                        <th>Property Name</th>
                        <th>Bid Status</th>
                    </tr>                   
                </thead>
                <tbody>
                    {
                      bids.map(bid =>(
                        <tr key={bid.bidId}>
                            <td>{bid.timeOfBid}</td>
                            <td>{bid.bidAmount}</td>
                            <td><Link to={`/lands-and-layouts/plot/${bid.landId}`}>{bid.propertyName}</Link></td>
                            <td>{bid.status}</td>
                        </tr>
                        )
                      )
                    }
                </tbody>
            </table> 
            }
        </div>
        </section>
        </>
    )
}

export default Mybids
