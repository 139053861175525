import React from 'react'
import { BrowserRouter, Switch,Route } from 'react-router-dom'
import NavMenu from './NavMenu'
import Home from '../Home'
import Concept from '../Concept'
import HowItWorks from '../HowItWorks'
import Faq from '../Faq'
import Contact from '../Contact'
import Login from '../Login'
import Signup from '../Signup'
import PageNotFound from './PageNotFound'
import Footer from './Footer'
import MyAccount from '../user/MyAccount'
import LandDetails from '../LandsAndLayouts/LandDetails'
import Layouts from '../LandsAndLayouts/Layouts'
import LayoutDetails from '../LandsAndLayouts/LayoutDetails'
import Mybids from '../LandsAndLayouts/Mybids'
import MyProfile from '../user/MyProfile'
import EditProfile from '../user/EditProfile'
import ForgotPassword from '../ForgotPassword'
import PasswordReset from '../PasswordReset'
import InitiatePayment from '../Payment/InitiatePayment'
import PaymentStatus from '../Payment/PaymentStatus'
import PaymentList from '../Payment/PaymentList'
import Receipt from '../Payment/Receipt'
import Receipt2 from '../Payment/Receipt2'

const Layout = () => {
    return (
        <BrowserRouter>
           <NavMenu /> 
           <Switch>
               <Route exact path='/' component={Home} />
               {/* <Route path='/the-concept' component={Concept} /> */}
               <Route path='/how-it-works' component={HowItWorks} />
               <Route path='/faq' component={Faq} />
               <Route path='/contact-us' component={Contact} />
               <Route path='/login' component={Login} />
               <Route path='/signup' component={Signup} />
               <Route path="/lands-and-layouts/plot/:id" component={LandDetails} />
               <Route path='/resetpassword' component={PasswordReset} />
               <Route path="/lands-and-layouts" component={Layouts} />
               <Route path='layout-details' component={LayoutDetails}/>
               <Route path='/land' component={LandDetails} />
               <Route path='/my-bids' component={Mybids} />
               <Route path='/my-account' component={MyAccount} />
               <Route path='/my-profile' component={MyProfile} />
               <Route path='/edit-profile' component={EditProfile} />
               <Route path='/pay' component={InitiatePayment} />
               <Route path='/payment/status' component={PaymentStatus} />
               <Route path='/my/payments' component={PaymentList} />
               <Route path='/payments/receipt/:id' component={Receipt2} />
               <Route path='/payments/receipts/:id' component={Receipt2} />
               <Route path='/forgot-password' component={ForgotPassword} />
               <Route path='*' component={PageNotFound} />
           </Switch>
           <Footer />
        </BrowserRouter>
    )
    
}

export default Layout
